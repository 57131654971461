import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div
      style={{
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      <h1 style={{ fontSize: '4em' }}>NOT FOUND</h1>
      <p style={{ fontSize: '2em' }}>
        You're looking for something that doesn&#39;t exist... the sadness.
        <span role="img" aria-label="crying face emoji">
          {' '}
          😭{' '}
        </span>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
